import React from 'react';
import Button from '../utils/components/Button';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const [changeIcon, setChangeIcon] = React.useState(false);
  const nav = useNavigate();
  return (
    <div className="flex items-start justify-center h-[80%] bg-sky-100">
      <div className="flex md:flex-row flex-col text-center justify-center items-center">
        <div
          className="flex w-fit"
          onMouseOver={() => {
            setChangeIcon(true);
          }}
          onMouseOut={() => {
            setChangeIcon(false);
          }}
        >
          <div className={changeIcon ? '' : 'hidden'}>
            <svg
              width="400"
              height="400"
              viewBox="0 0 600 600"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.998 190.975C51.0286 169.45 64.9086 152 87 152H357C379.091 152 401.029 169.45 405.998 190.975L469.884 467.696C474.854 489.221 460.974 506.671 438.882 506.671H168.882C146.791 506.671 124.854 489.221 119.884 467.696L55.998 190.975Z"
                fill="url(#paint0_linear_156_2)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M336.5 80C324.074 80 316.266 89.8154 319.061 101.923L327.047 136.513L330.084 149.667L330.309 150.642C333.104 162.75 345.444 172.565 357.87 172.565C370.297 172.565 378.104 162.75 375.309 150.642L375.084 149.667L372.047 136.513L364.061 101.923C361.266 89.8154 348.926 80 336.5 80ZM153.286 102.898C150.491 90.7898 158.299 80.9744 170.725 80.9744C183.151 80.9744 195.491 90.7898 198.286 102.898L206.047 136.513L209.309 150.642C212.104 162.75 204.297 172.565 191.87 172.565C179.444 172.565 167.104 162.75 164.309 150.642L161.047 136.513L153.286 102.898ZM317.047 136.513H216.047L219.309 150.642C223.347 168.131 212.069 182.309 194.12 182.309C176.171 182.309 158.347 168.131 154.309 150.642L151.047 136.513H111.047C95.5832 136.513 85.8672 148.728 89.3458 163.796L156.831 456.107C160.31 471.174 175.666 483.389 191.13 483.389H502.13C517.594 483.389 527.31 471.174 523.831 456.107L456.346 163.796C452.867 148.728 437.511 136.513 422.047 136.513H382.047L385.309 150.642C389.347 168.131 378.069 182.309 360.12 182.309C342.171 182.309 324.347 168.131 320.309 150.642L317.047 136.513ZM129.193 228.104L181.831 456.107C182.204 457.721 183.849 459.03 185.506 459.03H496.506C498.163 459.03 499.204 457.721 498.831 456.107L446.193 228.104H129.193Z"
                fill="#083488"
              />
              <rect
                width="318"
                height="238"
                transform="matrix(1 0 0.224951 0.97437 137 219)"
                fill="#48D3FF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M345.5 71C333.074 71 325.266 80.8154 328.061 92.9233L336.047 127.513L339.084 140.667L339.309 141.642C342.104 153.75 354.444 163.565 366.87 163.565C379.297 163.565 387.104 153.75 384.309 141.642L384.084 140.667L381.047 127.513L373.061 92.9233C370.266 80.8154 357.926 71 345.5 71ZM162.286 93.8977C159.491 81.7898 167.299 71.9744 179.725 71.9744C192.151 71.9744 204.491 81.7898 207.286 93.8977L215.047 127.513L218.309 141.642C221.104 153.75 213.297 163.565 200.87 163.565C188.444 163.565 176.104 153.75 173.309 141.642L170.047 127.513L162.286 93.8977ZM326.047 127.513H225.047L228.309 141.642C232.347 159.131 221.069 173.309 203.12 173.309C185.171 173.309 167.347 159.131 163.309 141.642L160.047 127.513H120.047C104.583 127.513 94.8672 139.728 98.3458 154.796L165.831 447.107C169.31 462.174 184.666 474.389 200.13 474.389H511.13C526.594 474.389 536.31 462.174 532.831 447.107L465.346 154.796C461.867 139.728 446.511 127.513 431.047 127.513H391.047L394.309 141.642C398.347 159.131 387.069 173.309 369.12 173.309C351.171 173.309 333.347 159.131 329.309 141.642L326.047 127.513ZM138.193 219.104L190.831 447.107C191.204 448.721 192.849 450.03 194.506 450.03H505.506C507.163 450.03 508.204 448.721 507.831 447.107L455.193 219.104H138.193Z"
                fill="#3C82F6"
              />
              <path
                d="M263.997 338.772C246.84 338.772 230.04 325.168 226.473 308.386L220.083 278.321C216.516 261.539 227.532 247.934 244.689 247.934V247.934C261.846 247.934 278.646 261.539 282.213 278.321L288.604 308.386C292.171 325.168 281.154 338.772 263.997 338.772V338.772Z"
                fill="#1B1BD8"
              />
              <rect
                width="92.8672"
                height="62.1304"
                rx="31.0652"
                transform="matrix(-0.207912 -0.978148 1 0 358.308 334.634)"
                fill="#1B1BD8"
              />
              <path
                d="M275.249 390.764C273.274 381.473 280.77 374.665 290.463 376.947L335.227 387.484C345.024 389.791 354.702 389.136 362.855 385.616L394.437 371.982C404.589 367.6 418.41 374.969 421.892 386.622V386.622C424.726 396.11 419.359 404.472 409.859 405.369L360.754 410.003C356.57 410.398 352.275 410.401 347.924 410.011L291.484 404.957C283.848 404.273 276.813 398.123 275.249 390.764V390.764Z"
                fill="#1B1BD8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M373.696 396.668C373.688 397.466 374.08 398.334 374.826 398.988L396.48 417.962C397.712 419.042 399.445 419.129 400.351 418.157C401.256 417.184 400.991 415.52 399.759 414.44L378.346 395.678L373.696 396.668Z"
                fill="#FF7A00"
              />
              <path
                d="M271.731 333.976C254.574 333.976 237.774 320.372 234.207 303.59L227.817 273.524C224.25 256.743 235.266 243.138 252.423 243.138V243.138C269.58 243.138 286.38 256.743 289.947 273.524L296.338 303.59C299.905 320.372 288.888 333.976 271.731 333.976V333.976Z"
                fill="#3C82F6"
              />
              <rect
                width="92.8672"
                height="62.1304"
                rx="31.0652"
                transform="matrix(-0.207912 -0.978148 1 0 366.042 329.838)"
                fill="#3C82F6"
              />
              <path
                d="M282.983 385.968C281.008 376.676 288.504 369.869 298.197 372.151L342.96 382.688C352.758 384.994 362.436 384.34 370.589 380.82L402.171 367.186C412.323 362.803 426.144 370.173 429.626 381.826V381.826C432.46 391.313 427.093 399.676 417.593 400.572L368.488 405.207C364.304 405.602 360.009 405.604 355.658 405.215L299.218 400.16C291.581 399.476 284.547 393.327 282.983 385.968V385.968Z"
                fill="#3C82F6"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M361.786 395.855C362.813 398.481 364.548 401.013 366.941 403.14L395.564 428.58C402.365 434.625 411.908 435.105 416.877 429.652L420.298 425.899C425.267 420.446 423.781 411.125 416.98 405.08L398.314 388.491L369.526 394.672C367.001 395.214 364.416 395.609 361.786 395.855Z"
                fill="#FF8500"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M366.231 394.925C367.25 397.605 369.014 400.194 371.471 402.361L397.881 425.655C404.706 431.674 414.293 432.157 419.294 426.733L421.871 423.938C426.872 418.514 425.393 409.237 418.568 403.217L401.183 387.883L373.385 393.822C371.049 394.321 368.661 394.689 366.231 394.925Z"
                fill="#FFD600"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M381.43 391.871C381.422 392.67 381.813 393.538 382.559 394.191L404.213 413.165C405.446 414.245 407.179 414.333 408.084 413.36C408.99 412.388 408.724 410.724 407.492 409.644L386.08 390.882L381.43 391.871Z"
                fill="#FF7A00"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_156_2"
                  x1="222"
                  y1="152"
                  x2="222"
                  y2="506.671"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3EC3F5" />
                  <stop offset="1" stopColor="#4624D8" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className={changeIcon ? 'hidden' : ''}>
            <svg
              width="400"
              height="400"
              viewBox="0 0 600 600"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.998 190.975C51.0286 169.45 64.9086 152 87 152H357C379.091 152 401.029 169.45 405.998 190.975L469.884 467.696C474.854 489.221 460.974 506.671 438.882 506.671H168.882C146.791 506.671 124.854 489.221 119.884 467.696L55.998 190.975Z"
                fill="url(#paint0_linear_123_468)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M336.5 80C324.074 80 316.266 89.8154 319.061 101.923L327.047 136.513L330.084 149.667L330.309 150.642C333.104 162.75 345.444 172.565 357.87 172.565C370.297 172.565 378.104 162.75 375.309 150.642L375.084 149.667L372.047 136.513L364.061 101.923C361.266 89.8154 348.926 80 336.5 80ZM153.286 102.898C150.491 90.7898 158.299 80.9744 170.725 80.9744C183.151 80.9744 195.491 90.7898 198.286 102.898L206.047 136.513L209.309 150.642C212.104 162.75 204.297 172.565 191.87 172.565C179.444 172.565 167.104 162.75 164.309 150.642L161.047 136.513L153.286 102.898ZM317.047 136.513H216.047L219.309 150.642C223.347 168.131 212.069 182.309 194.12 182.309C176.171 182.309 158.347 168.131 154.309 150.642L151.047 136.513H111.047C95.5832 136.513 85.8672 148.728 89.3458 163.796L156.831 456.107C160.31 471.174 175.666 483.389 191.13 483.389H502.13C517.594 483.389 527.31 471.174 523.831 456.107L456.346 163.796C452.867 148.728 437.511 136.513 422.047 136.513H382.047L385.309 150.642C389.347 168.131 378.069 182.309 360.12 182.309C342.171 182.309 324.347 168.131 320.309 150.642L317.047 136.513ZM129.193 228.104L181.831 456.107C182.204 457.721 183.849 459.03 185.506 459.03H496.506C498.163 459.03 499.204 457.721 498.831 456.107L446.193 228.104H129.193Z"
                fill="#083488"
              />
              <rect
                width="318"
                height="238"
                transform="matrix(1 0 0.224951 0.97437 137 219)"
                fill="#48D3FF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M345.5 71C333.074 71 325.266 80.8154 328.061 92.9233L336.047 127.513L339.084 140.667L339.309 141.642C342.104 153.75 354.444 163.565 366.87 163.565C379.297 163.565 387.104 153.75 384.309 141.642L384.084 140.667L381.047 127.513L373.061 92.9233C370.266 80.8154 357.926 71 345.5 71ZM162.286 93.8977C159.491 81.7898 167.299 71.9744 179.725 71.9744C192.151 71.9744 204.491 81.7898 207.286 93.8977L215.047 127.513L218.309 141.642C221.104 153.75 213.297 163.565 200.87 163.565C188.444 163.565 176.104 153.75 173.309 141.642L170.047 127.513L162.286 93.8977ZM326.047 127.513H225.047L228.309 141.642C232.347 159.131 221.069 173.309 203.12 173.309C185.171 173.309 167.347 159.131 163.309 141.642L160.047 127.513H120.047C104.583 127.513 94.8672 139.728 98.3458 154.796L165.831 447.107C169.31 462.174 184.666 474.389 200.13 474.389H511.13C526.594 474.389 536.31 462.174 532.831 447.107L465.346 154.796C461.867 139.728 446.511 127.513 431.047 127.513H391.047L394.309 141.642C398.347 159.131 387.069 173.309 369.12 173.309C351.171 173.309 333.347 159.131 329.309 141.642L326.047 127.513ZM138.193 219.104L190.831 447.107C191.204 448.721 192.849 450.03 194.506 450.03H505.506C507.163 450.03 508.204 448.721 507.831 447.107L455.193 219.104H138.193Z"
                fill="#3C82F6"
              />
              <rect
                width="33.726"
                height="108.961"
                rx="16.863"
                transform="matrix(0.885235 0.663877 -0.516683 0.713207 411.24 245)"
                fill="#1B1BD8"
              />
              <rect
                width="33.726"
                height="108.961"
                rx="16.863"
                transform="matrix(-0.578699 0.663877 0.845997 0.713207 361.687 245)"
                fill="#1B1BD8"
              />
              <rect
                width="33.726"
                height="108.961"
                rx="16.863"
                transform="matrix(0.885235 0.663877 -0.516683 0.713207 416.24 235)"
                fill="#3C82F6"
              />
              <rect
                width="33.726"
                height="108.961"
                rx="16.863"
                transform="matrix(-0.578699 0.663877 0.845997 0.713207 366.687 235)"
                fill="#3C82F6"
              />
              <rect
                width="34.7237"
                height="112.184"
                rx="17.3619"
                transform="matrix(0.885235 0.663877 -0.516683 0.713207 267.052 243)"
                fill="#1B1BD8"
              />
              <rect
                width="34.7237"
                height="112.184"
                rx="17.3619"
                transform="matrix(-0.578699 0.663877 0.845997 0.713207 216.033 243)"
                fill="#1B1BD8"
              />
              <rect
                width="33.726"
                height="108.961"
                rx="16.863"
                transform="matrix(0.885235 0.663877 -0.516683 0.713207 273.24 235)"
                fill="#3C82F6"
              />
              <rect
                width="26.6184"
                height="108.961"
                rx="13.3092"
                transform="matrix(-0.578699 0.663877 0.845997 0.713207 219.574 239.719)"
                fill="#3C82F6"
              />
              <path
                d="M295 408.418C308.167 377.918 345.3 335.218 388.5 408.418"
                stroke="#1B1BD8"
                strokeWidth="23"
              />
              <path
                d="M300 400.418C313.167 369.918 352.8 331.8 396 405"
                stroke="#3C82F6"
                strokeWidth="23"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_123_468"
                  x1="222"
                  y1="152"
                  x2="222"
                  y2="506.671"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3EC3F5" />
                  <stop offset="1" stopColor="#4624D8" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="px-8 md:text-left md:w-[60%] mb-10">
          <p className="text-6xl font-bold mb-3">404 Error: Not Found</p>
          <p className="text-2xl mb-10 text-gray-600">
            Unfortunately, this page does not exist. Please check your code or
            URL and make sure it is valid.
          </p>
          <Button
            bgColor="blue-500"
            onClick={() => nav('/')}
            textColor={'white'}
          >
            Back Home
          </Button>
        </div>
      </div>
    </div>
  );
}
